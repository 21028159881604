import {ControlType} from './ControlType';
import {ControlField} from './ControlField';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {Reference} from '../../../../../../domain/Reference';
import {ControlFamily} from './ControlFamily';

export class ControlResult {
  @Transform(ReferenceTransformer.transformTo(ControlFamily))
  controlFamily: Reference<ControlFamily>
  controlType: ControlType;
  date: string;
  computed: boolean;
  score: number;
  status: ControlResultRiskStatus;
  @Type(() => ControlField)
  controlField: {[key: string]: ControlField};
  description: string;
  evaluatedScore: number;

  get isBlacklistPepControl(): boolean {
    return [ControlType.BlacklistControl, ControlType.PepControl].indexOf(this.controlType) > -1
  }
}

export enum ControlResultRiskStatus {
  // Manually added
  MATCH = 'MATCH',
  NO_MATCH = 'NO_MATCH',
  UNKNOWN = 'UNKNOWN',

  // Added by control
  POTENTIAL_RISK = 'POTENTIAL_RISK',
  NO_RISK_FOUND = 'NO_RISK_FOUND',
  NOT_COMPUTABLE = 'NOT_COMPUTABLE',
  STRUCK_OFF = 'STRUCK_OFF',
}
