import {PersonRiskStatusStats} from './PersonRiskStatusStats';
import {PersonDataCategoryStats} from './PersonDataCategoryStats';
import {Transform, Type} from 'class-transformer';
import {PersonCoreIndicatorCompute} from './PersonCoreIndicatorCompute';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {Company} from '../../company/Company';
import {Reference} from '../../../../../domain/Reference';
import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {PersonDataSource} from '../data-source/PersonDataSource';

/**
 * @deprecated not used anymore
 */
export class PersonCoreIndicator extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'personCoreIndicator';

  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  personDataSourceRef: Reference<PersonDataSource>
  @Type(() => PersonRiskStatusStats)
  personRiskStatusStats: {[key: string]: PersonRiskStatusStats} = {};
  @Type(() => PersonDataCategoryStats)
  personDataCategoriesStats: {[key: string]: PersonDataCategoryStats} = {}
  @Type(() => PersonCoreIndicatorCompute)
  personCoreIndicatorCompute: PersonCoreIndicatorCompute

  constructor() {
    super('com.beclm.person.api.personCoreIndicator.PersonCoreIndicator');
  }
}

export class PersonCoreIndicatorCompanyTotal {
  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;

  totalPerson: number;
}
