import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {SireneService} from '../modules/core/services/person/sirene.service';
import {BooleanResponse} from '../domain/BooleanResponse';
import {CustomValidators} from '../utils/CustomValidators';

export class UniqueSirenValidator {
  private initialSiren: string;
  private personDataSourceName: string;
  private isCompany: boolean;
  private checkExistingSiren: boolean;
  private personDataSourceNameRequired: boolean;
  private checkLegalEntitySirenRegistered: (siren: string, personDataSourceName: string) => Observable<boolean> = this.sireneService.legalEntitySirenIsRegistered

  constructor(private sireneService: SireneService) {}

  setInitialSiren(initialSiren: string): void {
    this.initialSiren = initialSiren;
  }

  setPersonDataSourceName(personDataSourceName: string): void {
    this.personDataSourceName = personDataSourceName;
  }

  setIsCompany(isCompany: boolean): void {
    this.isCompany = isCompany;
  }

  setCheckExistingSiren(checkExistingSiren: boolean): void {
    this.checkExistingSiren = checkExistingSiren;
  }

  setPersonDataSourceNameRequired(personDataSourceNameRequired: boolean) {
    this.personDataSourceNameRequired = personDataSourceNameRequired;
    if (this.personDataSourceNameRequired) {
      this.checkLegalEntitySirenRegistered = (siren: string, personDataSourceName: string) => this.sireneService.legalEntitySirenIsRegisteredOnPersonDataSource(siren, personDataSourceName)
    } else {
      this.checkLegalEntitySirenRegistered = (siren: string, personDataSourceName: string) => this.sireneService.legalEntitySirenIsRegistered(siren, personDataSourceName)
    }
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.existingSiren(this.sireneService, control);
  }

  existingSiren(
    sirenService: SireneService,
    control: AbstractControl
  ): Observable<ValidationErrors> {
    if (!this.isCompany) {
      return this.existingLegalEntity(sirenService, control);
    } else if (this.checkExistingSiren) {
      return this.existingCompany(sirenService, control);
    } else {
      return of(null);
    }
  }

  private existingLegalEntity(
    sirenService: SireneService,
    control: AbstractControl
  ): Observable<any> {
    if (control.value == null || control.value == '') {
      return of(null);
    }

    return this.checkLegalEntitySirenRegistered(control.value, this.personDataSourceName)
      .pipe(first())
      .pipe(
        map((isUnique: boolean) => {
          if (this.checkExistingSiren) {
            return isUnique && this.initialSiren !== control.value.toString()
              ? CustomValidators.buildError('sirenAlreadyRegistered', true)
              : null;
          } else {
            return null;
          }
        })
      );
  }

  private existingCompany(
    sirenService: SireneService,
    control: AbstractControl
  ) {
    return sirenService
      .companySirenIsRegistered(control.value)
      .pipe(first())
      .pipe(
        map((isUnique: BooleanResponse) => {
          return isUnique.value &&
            this.initialSiren !== control.value.toString()
            ? CustomValidators.buildError('sirenAlreadyRegistered', true)
            : null;
        })
      );
  }
}
