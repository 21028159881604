import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Reference} from '../../../../domain/Reference';
import {Type} from 'class-transformer';
import {ExportDescriptorColumn} from './ExportDescriptorColumn';
import {Uri} from '../../../../domain/Uri';
import {CompanyRefName} from '../company/CompanyRefName';

export type ExportModule = 'blacklist-pep' | 'compliance-binder' | 'core' | 'e-learning';

export function getAllModules(): ExportModule[] {
  return ['blacklist-pep', 'compliance-binder', 'core', 'e-learning'];
}

export function getAuditorModules(): ExportModule[] {
  return ['blacklist-pep', 'core'];
}

export class ExportDescriptor extends ClassedEntity {
  public static readonly COLLECTION: string = 'exportDescriptor';

  public id: string;
  public name: string;
  public label: string;
  public module: ExportModule;
  public technicalDescription: string;
  public functionalDescription: string;
  public isDefault: boolean;
  @Type(() => CompanyRefName)
  public companies: Array<CompanyRefName> = new Array<CompanyRefName>();
  public columns: Array<ExportDescriptorColumn>;
  constructor() {
    super();
  }

  toReference() {
    return new Reference<ExportDescriptor>(new Uri(this.module, ExportDescriptor.COLLECTION, this.id))
  }
}
